import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import ClientMenu from '../../../assets/lookup/client-menu.json';
import SystemMenu from '../../../assets/lookup/system-menu.json';

/**
 * Service for shared functionalities across the application. Manages settings, icons, and menus.
 */
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  /**
   * BehaviorSubject that tracks the visibility of a spinner for loading indicators.
   */
  spinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Instance of Router for navigation.
   */
  #router = inject(Router);
  /**
   * Instance of ActivatedRoute for navigation.
   */
  #activatedRoute = inject(ActivatedRoute);

  /**
   * Stores client channel settings in local storage.
   *
   * @param ChannelsSettings - The settings to be stored.
   */
  setClientChannelsSettings(ChannelsSettings) {
    localStorage.setItem('ClientChannelsSettings', JSON.stringify(ChannelsSettings));
  }

  /**
   * Retrieves client channel settings from local storage.
   *
   * @returns string - The stored settings or an empty string if not found.
   */
  getClientChannelsSettings(): string {
    return localStorage.getItem('ClientChannelsSettings') || '';
  }

  /**
   * Retrieves the feather icon associated with a module key and optional step key.
   *
   * @param moduleKey - The module key for which to get the icon.
   * @param stepKey - Optional step key for more specific icons.
   * @returns string - The feather icon name.
   */
  getFeatherIconByModuleKey(moduleKey: string, stepKey?: string) {
    let icon = '';
    switch (moduleKey) {
      case 'vitals':
        icon = stepKey ? this.getFeatherIconByVitalKey(stepKey) : 'vitals-signs';
        break;
      case 'healthcheck':
        icon = 'heart';
        break;
      case 'mymedication':
        icon = 'medications';
        break;
      case 'esignature':
        icon = 'esignature';
        break;
      case 'transition':
        icon = 'transition';
        break;
      default:
        icon = 'no-preview';
        break;
    }
    return icon;
  }

  /**
   * Retrieves the feather icon associated with a specific vital key.
   *
   * @param stepKey - The vital key for which to get the icon.
   * @returns string - The feather icon name.
   */
  getFeatherIconByVitalKey(stepKey: string) {
    let icon = '';
    const vitalKey = stepKey.toLowerCase();
    switch (vitalKey) {
      case 'skintemperature':
      case 'temperature':
        icon = 'thermometer';
        break;
      case 'bloodpressure':
      case 'bloodpressure_diastolic':
      case 'bloodpressure_systolic':
        icon = 'bloodpressure';
        break;
      case 'activity':
      case 'movement':
        icon = 'movement';
        break;
      case 'heartrate':
      case 'heartratevariability':
        icon = 'heart-rate';
        break;
      case 'oxygen':
        icon = 'oxygen';
        break;
      case 'bloodsugar':
        icon = 'blood-sugar';
        break;
      case 'sleepdeep':
      case 'sleeplight':
      case 'sleepquality':
        icon = 'sleep';
        break;
      case 'sleeprem':
        icon = 'sleeprem';
        break;
      case 'sleeptimeswoken':
        icon = 'sleeptimeswoken';
        break;
      case 'sleepefficiency':
        icon = 'sleep';
        break;
      case 'weight':
        icon = 'weight';
        break;
      default:
        icon = 'no-preview';
        break;
    }
    return icon;
  }

  /**
   * Retrieves the client menu configuration.
   *
   * @returns any - The client menu configuration.
   */
  getClientMenu() {
    return ClientMenu;
  }

  /**
   * Retrieves the system menu configuration.
   *
   * @returns any - The system menu configuration.
   */
  getSystemMenu() {
    return SystemMenu;
  }
  /**
   * Get client ID from the url params.
   */
  getClientId() {
    let clientId = this.#activatedRoute.snapshot.queryParamMap.get('clientId') || '';
    if (!clientId) {
      const queryParams = new URLSearchParams(this.#router.url);
      try {
        clientId = queryParams?.get('clientId') || '';
      } catch {
        clientId = '';
      }
    }
    return clientId;
  }
}
