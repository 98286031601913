import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';

/**
 * This service provides methods to perform HTTP operations (GET, POST, PUT, DELETE, PATCH).
 * It also manages a loader state using `BehaviorSubject` to indicate when a request is being processed.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  /**
   * BehaviorSubject to manage the loader state.
   * Emits true or false based on whether a request is being processed.
   */
  public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Creates an instance of the class.
   * @param http - Instance of HttpClient for making HTTP requests.
   * @param sharedService - Instance of SharedService for managing shared states like loader visibility.
   */
  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
  ) {}

  /**
   * Performs an HTTP GET request.
   *
   * @param url - The URL for the HTTP GET request.
   * @param header - Optional headers for the request.
   * @param hideLoader - Optional flag to hide the loader.
   * @param responseType - Optional response type for the request.
   * @returns Observable<any> - An Observable of the HTTP response.
   */
  get(url: string, header?: any, hideLoader = false, responseType?: any): Observable<any> {
    let params = new HttpParams();
    let headers = new HttpHeaders();
    for (const key in header) {
      params = params.append(key, header[key]);
    }
    if (hideLoader) {
      headers = headers.append('loader', hideLoader.toString());
    }
    this.showLoader(hideLoader);
    return this.http.get(url, { params, headers, responseType });
  }

  /**
   * Performs an HTTP POST request.
   *
   * @param url - The URL for the HTTP POST request.
   * @param body - The body of the HTTP POST request.
   * @param header - Optional headers for the request.
   * @returns Observable<any> - An Observable of the HTTP response.
   */
  post(url: string, body?: object, header?: object): Observable<any> {
    return this.http.post(url, body, header);
  }

  /**
   * Performs an HTTP PUT request.
   *
   * @param url - The URL for the HTTP PUT request.
   * @param body - The body of the HTTP PUT request.
   * @param header - Optional headers for the request.
   * @returns Observable<any> - An Observable of the HTTP response.
   */
  put(url: string, body: object, header?: object): Observable<any> {
    return this.http.put(url, body, header);
  }

  /**
   * Performs an HTTP DELETE request.
   *
   * @param url - The URL for the HTTP DELETE request.
   * @param header - Optional headers for the request.
   * @returns Observable<any> - An Observable of the HTTP response.
   */
  delete(url: string, header?: object): Observable<any> {
    return this.http.delete(url, header);
  }

  /**
   * Performs an HTTP PATCH request.
   *
   * @param url - The URL for the HTTP PATCH request.
   * @param body - The body of the HTTP PATCH request.
   * @param header - Optional headers for the request.
   * @param hideLoader - Optional flag to hide the loader.
   * @returns Observable<any> - An Observable of the HTTP response.
   */
  patch(url: string, body?: object, header?: object, hideLoader = false): Observable<any> {
    this.showLoader(hideLoader);
    return this.http.patch(url, body, header).pipe();
  }

  /**
   * Manages the visibility of the loader based on the `hideLoader` flag.
   *
   * @param hideLoader - Flag to control the visibility of the loader.
   */
  private showLoader(hideLoader: boolean): void {
    setTimeout(() => {
      if (hideLoader) {
        this.sharedService.spinner.next(!hideLoader);
      }
    }, 0);
  }
}
