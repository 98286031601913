import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Attachment } from 'src/app/shared/interfaces/common.interface';
import { Utils } from 'src/app/shared/utils/utils';
import { FileUploadService } from '../../file-upload.service';

/**
 * Component for handling file uploads, including validation, preview, and removal.
 */
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  /**
   * The selected file.
   */
  file!: File | null;
  /**
   * CSS class name to apply for error styling.
   */
  @Input() errorClassName = '';
  /**
   * URL of the currently uploaded file.
   */
  @Input() fileUrl!: string;
  /**
   * Name attribute for the file input.
   */
  @Input() name!: string;
  /**
   * Label to display for the file input field.
   */
  @Input() label!: string;
  /**
   * Allowed file type(s) for upload.
   */
  @Input() allowFileType!: string;
  /**
   * Type of file expected for upload.
   */
  @Input() fileType!: string;
  /**
   * Version of the API to use for file uploads (e.g., 'v1' or 'v2').
   */
  @Input() apiVersion = '';
  /**
   * Boolean to allow either file path or URL.
   */
  @Input() allowPathOrUrl = false;
  /**
   * Boolean to display a required mark on the file input.
   */
  @Input() showRequiredMark = false;
  /**
   * Boolean to show or hide the file removal icon.
   */
  @Input() showRemoveFileIcon = true;
  /**
   * Boolean to disable the file input field.
   */
  @Input() disabled = false;
  /**
   * Placeholder text for the file input field.
   */
  @Input() placeholder!: string;
  /**
   * Event emitter for the URL of the uploaded file.
   */
  @Output() fileUploaded: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitter for the name of the uploaded file.
   */
  @Output() fileName: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Event emitter for the MIME type of the uploaded file.
   */
  @Output() mimeType: EventEmitter<string> = new EventEmitter<string>();
  /**
   * The typed URL for the file.
   */
  typedUrl = '';
  /**
   * Error message for file size validation.
   */
  sizeError = '';

  /** Creates an instance of the class.
   * @param fileUploadService - Service for handling file uploads.
   * @param _toastrService - Service for displaying toast notifications.
   */
  constructor(
    private fileUploadService: FileUploadService,
    private _toastrService: ToastrService,
  ) {}
  /**
   * Lifecycle hook that resets the file when `fileUrl` changes.
   */
  ngOnChanges() {
    if (!this.fileUrl) {
      this.file = null;
    }
  }
  /**
   * Emits the URL of the uploaded file.
   * @param {string} fileUrl - URL of the uploaded file.
   */
  emitFileUrl(fileUrl: string) {
    this.fileUploaded.emit(fileUrl);
  }
  /**
   * Emits the name of the uploaded file.
   * @param {string} name - Name of the uploaded file.
   */
  emitFileName(name: string) {
    this.fileName.emit(name);
  }
  /**
   * Emits the MIME type of the uploaded file.
   * @param {string} mimeType - MIME type of the uploaded file.
   */
  mimeTypeUrl(mimeType: string) {
    this.mimeType.emit(mimeType);
  }
  /**
   * Checks if the given URL is valid.
   * @param {string} fileUrl - URL to be validated.
   * @returns {boolean} - Returns true if the URL is valid, false otherwise.
   */
  isValidUrl(fileUrl: string) {
    return Utils.isValidUrl(fileUrl);
  }
  /**
   * Returns the file icon based on the file URL.
   * @param {string} fileUrl - URL of the file.
   * @returns {string} - File icon for the given file URL.
   */
  getFileIcon(fileUrl: string) {
    return this.fileUploadService.getFileIcon(fileUrl);
  }
  /**
   * Handles the file upload process, including size validation and API call.
   * @param {Event} event - The file input change event.
   */
  uploadFile(event: Event) {
    if (this.fileType) {
      const target = event.target as HTMLInputElement;
      this.file = (target.files as FileList)[0];
      this.sizeError = '';
      const fileSize = this.file.size / 1024;
      if (fileSize > 30720) {
        this.sizeError = 'File size can not greater than 30 MB';
        return;
      }
      if (this.file) {
        const formData = new FormData();
        let uploadFileObservable;
        if (this.apiVersion && this.apiVersion === 'v2') {
          formData.append('attachment', this.file);
          uploadFileObservable = this.fileUploadService.uploadV2File(formData, this.fileType);
        } else {
          formData.append('file', this.file);
          uploadFileObservable = this.fileUploadService.uploadFile(formData, this.fileType);
        }

        uploadFileObservable.subscribe({
          next: (response) => {
            if (response) {
              if (response?.message?.url && this.apiVersion && this.apiVersion === 'v2') {
                response.url = response.message.url;
                response.name = response.message.filename;
              }
              if (response.url) {
                this._toastrService.success('Uploaded successfully');
                this.fileName.emit(response.name);
                this.emitFileUrl(response.url.trimEnd());
                this.mimeTypeUrl(response.mimetype);
              }
            }
          },
          error: (error) => {
            if (error) {
              //console.log(error);
            }
          },
        });
      }
    }
  }
  // onBlurUrlInput(event) {
  //   this.file = null;
  //   this.emitFileUrl(event.target.value);
  // }
  /**
   * Removes the currently selected file and emits empty values.
   */
  removeFile() {
    this.file = null;
    this.emitFileUrl('');
    this.emitFileName('');
  }
  /**
   * Opens a preview dialog for the selected attachment.
   * @param {Attachment} selectedAttachment - The attachment to preview.
   */
  openPreviewDialog(selectedAttachment: Attachment) {
    this.fileUploadService.openPreviewDialog(selectedAttachment);
  }
}
