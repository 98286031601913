import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { DocumentPreviewCarouselComponent } from '../../components/document-preview-carousel/document-preview-carousel.component';
import { Attachment } from '../../interfaces/common.interface';
import { HttpService } from '../../services/http.service';

/**
 * Service for handling file uploads, file type determination, MIME type retrieval,
 * and opening preview dialogs for documents.
 */
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  /** Creates an instance of the class.
   * @param apiUrl - Base URL for the API.
   * @param apiVersion - Version of the API to use.
   * @param v2ApiUrl - Base URL for the version 2 of the API.
   * @param dialog - MatDialog service for opening dialogs.
   * @param httpService - HttpService for making HTTP requests.
   */

  constructor(
    @Inject('API_BASE') private apiUrl: string,
    @Inject('API_VERSION') private apiVersion,
    @Inject('v2_API_BASE') private v2ApiUrl: string,
    private dialog: MatDialog,
    private httpService: HttpService,
  ) {}

  /**
   * Uploads a file to the server using FormData.
   *
   * @param file - The file to upload.
   * @param fileType - The type of the file being uploaded.
   * @param headers - Optional headers to include with the request.
   * @returns Observable of the server response.
   */
  uploadFile(file: FormData, fileType: string, headers?: object) {
    const url = `${this.apiUrl}/${this.apiVersion}/fileUpload?fileType=${fileType}`;
    return this.httpService.post(url, file, headers);
  }

  /**
   * Uploads a file to the server using version 2 of the API.
   *
   * @param file - The file to upload.
   * @param fileType - The type of the file being uploaded.
   * @param headers - Optional headers to include with the request.
   * @returns Observable of the server response.
   */
  uploadV2File(file: FormData, fileType: string, headers?: object) {
    const url = `${this.v2ApiUrl}/v2/document/upload?type=${fileType}`;
    return this.httpService.post(url, file, headers);
  }

  /**
   * Requests an access signature for a file upload.
   *
   * @param fileName - Optional name of the file.
   * @returns Promise resolving to an object containing the URL for file access.
   */
  public async uploadFileAccessSignature(
    fileName: string,
    container: 'DOCUMENT' | 'GOALS_TASKS',
  ): Promise<{ url: string }> {
    const url = `${this.apiUrl}/${this.apiVersion}/fileUpload/${fileName}?container=${container}`;
    return firstValueFrom(this.httpService.get(url));
  }

  /**
   * Extracts the file extension from a given URL.
   *
   * @param fileUrl - The URL of the file.
   * @returns The file extension or an empty string if none is found.
   */
  public getFileExtension(fileUrl: string) {
    let fileExt = '';
    if (fileUrl) {
      const FILE_EXT_REGEX = /.([0-9a-z]+)(?:[?#]|$)/i;
      const extension = fileUrl.match(FILE_EXT_REGEX);
      const INDEX_NUMBER = 1;
      fileExt = extension && extension[INDEX_NUMBER] ? extension[INDEX_NUMBER] : '';
    }
    return fileExt;
  }

  /**
   * Determines the type of a file based on its URL and extension.
   *
   * @param fileUrl - The URL of the file.
   * @returns A string indicating the file type (e.g., 'image', 'pdf', 'video', etc.).
   */
  public getFileType(fileUrl: string): string {
    let fileType = '';
    if (fileUrl) {
      const fileExt = this.getFileExtension(fileUrl);
      if (fileExt.match(/^(jpg|jpeg|png|gif|bmp|raw|svg|tiff|webp|heif|image\/png)$/)) {
        fileType = 'image';
      } else if (fileExt.match(/^pdf$/)) {
        fileType = 'pdf';
      } else if (fileExt.match(/^(doc|docx|dot|docm|dotx|dotm|docb|xls|xlsm|xltx|xltm|ppt|pot|pps|pptm|potx|potm)$/)) {
        fileType = 'worddoc';
      } else if (fileExt.match(/^(docx|xlsx|pptx|xdoc|ods)$/)) {
        fileType = 'docx';
      } else if (fileExt.match(/^(txt)$/)) {
        fileType = 'txt';
      } else if (fileExt.match(/^(mp3|ogg|oga|ogv)$/)) {
        fileType = 'audio';
      } else if (fileExt.match(/^(mp4|webm|flv|wmv|avi|mpg|mpeg|mov)$/)) {
        fileType = 'video';
      } else {
        if (
          fileUrl.match(
            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
          )
        ) {
          fileType = 'youtube';
        } else if (
          fileUrl.match(
            /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/,
          )
        ) {
          fileType = 'video';
        } else if (
          fileUrl.match(
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
          )
        ) {
          fileType = 'url';
        } else {
          fileType = 'other';
        }
      }
    }
    return fileType;
  }

  /**
   * Retrieves an appropriate icon for a file based on its URL and content type.
   *
   * @param fileUrl - The URL of the file.
   * @param contentType - Optional content type of the file.
   * @returns The URL of the icon representing the file type.
   */
  public getFileIcon(fileUrl: string, contentType?: string) {
    let icon = '';
    if (fileUrl) {
      const fileType = this.getFileType(fileUrl);

      switch (fileType) {
        case 'image':
          icon = fileUrl;
          break;
        case 'pdf':
          if (contentType) {
            icon = contentType;
          } else {
            icon = 'assets/images/pdf.png';
          }
          break;
        case 'worddoc':
        case 'txt':
        case 'docx':
          icon = 'assets/images/document and others.svg';
          break;
        case 'audio':
          icon = 'assets/images/audio.svg';
          break;
        case 'video':
          icon = 'assets/images/video.svg';
          break;
        // case 'youtube':
        //   icon = 'assets/images/youtube.png';
        //   break;
        // case 'url':
        //   icon = 'assets/images/link.png';
        //   break;
        case 'other':
          icon = 'assets/images/no-preview.png';
          break;
        default:
          icon = 'assets/images/no-preview.png';
          break;
      }
    }
    return icon;
  }

  /**
   * Determines the MIME type of a file based on its URL and extension.
   *
   * @param fileUrl - The URL of the file.
   * @returns The MIME type of the file.
   */
  public getMimeType(fileUrl: string): string {
    const fileExtension = this.getFileExtension(fileUrl);
    const fileType = this.getFileType(fileUrl);
    let mimeType = '';
    switch (fileType) {
      case 'image':
        switch (fileExtension) {
          case 'heif':
            mimeType = '';
            break;
          case 'png':
            mimeType = 'image/png';
            break;
          case 'bmp':
            mimeType = 'image/bmp';
            break;
          case 'jpg':
          case 'jpeg':
            mimeType = 'image/jpeg';
            break;
          case 'gif':
            mimeType = 'image/gif';
            break;
          case 'svg':
            mimeType = 'image/svg+xml';
            break;
          case 'webp':
            mimeType = 'image/webp';
            break;
          default:
            mimeType = '';
            break;
        }
        break;
      case 'video':
        switch (fileExtension) {
          case 'avi':
            mimeType = 'video/x-msvideo';
            break;
          case 'mp4':
            mimeType = 'video/mp4';
            break;
          case 'mpeg':
          case 'mpg':
            mimeType = 'video/mpeg';
            break;
          case 'webm':
            mimeType = 'video/webm';
            break;
          case 'mov':
            mimeType = 'video/quicktime';
            break;
          case 'flv':
            mimeType = 'video/x-flv';
            break;
          case 'oga':
          case 'ogv':
          case 'ogg':
            mimeType = 'video/ogg';
            break;
          default:
            mimeType = '';
            break;
        }
        break;
      case 'audio':
        switch (fileExtension) {
          case 'mp3':
            mimeType = 'audio/mpeg';
            break;
          case 'oga':
          case 'ogv':
          case 'ogg':
            mimeType = 'audio/ogg';
            break;
          default:
            mimeType = '';
            break;
        }
        break;
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      case 'text':
        mimeType = 'text/plain';
        break;
      default:
        mimeType = '';
        break;
    }
    return mimeType;
  }

  /**
   * Opens a dialog to preview documents.
   *
   * @param selectedAttachment - The currently selected attachment to be previewed.
   * @param attachments - Optional list of all attachments to be shown in the preview.
   */
  openPreviewDialog(selectedAttachment: Attachment, attachments?: Attachment[]) {
    this.dialog.open(DocumentPreviewCarouselComponent, {
      data: {
        attachments: attachments && attachments.length ? attachments : [],
        selectedAttachment: selectedAttachment,
      },
    });
  }
}
