import { Component, OnInit } from '@angular/core';
/**
 * A component that displays a thank you message in dialog after a submission.
 */
@Component({
  selector: 'app-thankyou-submission-dialog',
  templateUrl: './thankyou-submission-dialog.component.html',
  styleUrls: ['./thankyou-submission-dialog.component.scss'],
})
export class ThankyouSubmissionDialogComponent implements OnInit {
  /**
   * It initializes the component.
   */
  constructor() {}

  /**
   * Lifecycle hook that is called after the component has been initialized.
   */
  ngOnInit(): void {}
}
