import { Component, OnInit } from '@angular/core';
/**
 * A simple spinner component that displays a loading indicator. It is used to show a loading state while data is being fetched or processed.
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  /**
   * Constructor for the `SpinnerComponent`. Currently does not perform any specific actions.
   */
  constructor() {}

  /**
   * Angular lifecycle hook that is called after the component has been initialized.
   * Currently does not perform any specific actions.
   */
  ngOnInit(): void {}
}
