import { Location, NgTemplateOutlet } from '@angular/common';
import { Component, createNgModule, Injector, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';

/**
 * Interface representing the structure of tab data.
 */
export interface tabsDataType {
  /**
   * The key used to identify the tab.
   */
  key: string;

  /**
   * The label displayed on the tab.
   */
  label: string;
}

/**
 * Interface representing the module list with route and key.
 */
interface moduleList {
  /**
   * The route associated with the module.
   */
  route: string;

  /**
   * The key used to identify the module.
   */
  key: string;
}

/**
 * Component to display a tab group with dynamic modules.
 */
@Component({
  selector: 'app-mat-tab-group',
  standalone: true,
  imports: [MatTabGroup, MatTab, NgTemplateOutlet],
  templateUrl: './mat-tab-group.component.html',
  styleUrl: './mat-tab-group.component.scss',
})
export class MatTabGroupComponent implements OnInit {
  /**
   * Array of tab data to be displayed in the tab group.
   */
  @Input() tabsData!: Array<tabsDataType>;

  /**
   * Reference to the container where the dynamic components will be loaded.
   */
  @ViewChild('widgetComponent', { read: ViewContainerRef })
  public widgetComponent!: ViewContainerRef;

  /**
   * Title of the currently loaded component.
   */
  componentTitle!: string;
  /**
   * Selected mat tab group index
   */
  selectedTabIndex = 0;
  /**
   * Creates an instance of MatTabGroupComponent.
   * @param injector - The Angular dependency injector used for dynamic module loading.
   * @param cd - The change detector reference used for detecting changes in the component.
   */
  constructor(
    private injector: Injector,
    private location: Location,
    private route: ActivatedRoute,
  ) {}

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Selects the first tab by default.
   */
  ngOnInit(): void {
    this.getIndexByFragmentKey();
    this.selectedIndex(this.selectedTabIndex);
  }

  /**
   * Handles the tab selection and loads the corresponding module.
   * @param eve - The index of the selected tab.
   */
  selectedIndex(eve) {
    this.selectedTabIndex = eve;
    this.loadModule(this.tabsData[eve]);
  }
  /**
   * Handles adding fragment to the route according to tab selection.
   * @param fragment - The fragment of the selected tab.
   */
  addFragmentToRoutes(fragment: string) {
    if (fragment) {
      this.location.replaceState(this.location.path() + '#' + fragment);
    }
  }
  /**
   * Handles to setting tab index according to the current fragment.
   */
  getIndexByFragmentKey() {
    this.selectedTabIndex = 0;
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.selectedTabIndex = this.tabsData?.findIndex((t) => t.key?.toLowerCase() === fragment?.toLowerCase());
      }
    });
  }
  /**
   * Dynamically loads a module based on the selected tab's key.
   * @param loadFile - The data of the selected tab.
   */
  private async loadModule(loadFile): Promise<void> {
    let moduleRef;
    let componentRef;
    this.addFragmentToRoutes(loadFile.key);
    switch (
      loadFile.key // Need to add more module route as per the requirements.
    ) {
      case 'assessmentLibrary':
        const { AssessmentLibraryModule } = await import(
          'src/app/modules/assessment-library/assessment-library.module'
        );
        this.componentTitle = 'overview';
        moduleRef = createNgModule(AssessmentLibraryModule, this.injector);
        break;
      case 'guidanceTemplates':
        const { GuidanceTemplatesModule } = await import(
          'src/app/modules/guidance-templates/guidance-templates.module'
        );
        this.componentTitle = 'Resources';
        moduleRef = createNgModule(GuidanceTemplatesModule, this.injector);
        break;
      case 'programs':
        const { ProgramsModule } = await import('src/app/modules/programs/programs.module');
        this.componentTitle = 'Programs';
        moduleRef = createNgModule(ProgramsModule, this.injector);
        break;

      default:
    }

    this.widgetComponent.clear();
    if (!componentRef) {
      componentRef = moduleRef.instance.getComponent();
    }

    const componentInstance: any = this.widgetComponent.createComponent(componentRef, {
      ngModuleRef: moduleRef,
    }).instance;
  }
}
