<div class="auth-wrapper p-3">
  <div class="logo text-center">
    <img src="assets/images/logo/medocity_logo.png" alt="Medocity logo" />
  </div>
  <div class="auth-container">
    <h1>{{ 'signIn' | translate }}</h1>
    <form [formGroup]="loginForm" (ngSubmit)="submit()">
      <div class="mb-3">
        <label for="userName" class="form-label">{{ 'username' | translate }}</label>
        <input
          type="email"
          id="userName"
          class="form-control"
          formControlName="username"
          [class]="{
            'is-invalid': loginFormControls.username.invalid && (submitted || loginFormControls.username.touched)
          }"
        />
        @if (loginFormControls.username.invalid && (submitted || loginFormControls.username.touched)) {
          <div class="invalid-feedback">
            @if (loginFormControls.username.errors?.['required']) {
              <div>Username is required.</div>
            }
          </div>
        }
      </div>
      <div class="mb-3">
        <div class="d-flex justify-content-between">
          <label for="paswword" class="form-label">{{ 'password' | translate }}</label>
          <a routerLink="/forgot-password" class="text-decoration-none">{{ 'forgotPassword?' | translate }}</a>
        </div>
        <input
          type="password"
          id="paswword"
          class="form-control"
          formControlName="password"
          [class]="{
            'is-invalid': loginFormControls.password.invalid && (submitted || loginFormControls.password.touched)
          }"
        />
        @if (loginFormControls.password.invalid && (submitted || loginFormControls.password.touched)) {
          <div class="invalid-feedback">
            @if (loginFormControls.password.errors?.['required']) {
              <div>
                {{ 'passwordIsRequired' | translate }}
              </div>
            }
            @if (loginFormControls.password.errors?.['minlength']) {
              <div>
                Password should be more than 8 characters.
                {{ 'passwordShouldBeMoreThan8Characters' | translate }}
              </div>
            }
          </div>
        }
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary">
          {{ 'signIn' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>
