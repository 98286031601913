import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Statuspage } from 'statuspage.io';

/**
 * Component to render the footer of the application.
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  /** Flag to indicate if the current route is the dashboard */
  isDashboardRoute = false;
  /** Current year */
  year = new Date()?.getFullYear();
  /** Application running status */
  appRunningStatus = '';
  /** Application version */
  appVersion = environment.appConfig.version;

  /**
   * Constructor to inject dependencies and initialize route event handling
   * @param router Router service to handle navigation events
   */
  constructor(private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.isDashboardRoute = event.url.includes('/dashboard') ? true : false;
      }
    });
  }

  /**
   * OnInit lifecycle hook to initialize the status page and check application status
   */
  ngOnInit(): void {
    const statuspage = new Statuspage('c4h4x9x7hzk0');
    statuspage.api.getComponents().then((response) => {
      this.appRunningStatus = this.statusChecker(response);
    });
  }

  /**
   * Checks the status of the application from the status page response
   * @param response Response from the status page API
   */
  statusChecker(response: any) {
    let appStatus = '';
    for (let i = 0; i < response.components.length; i++) {
      if (response.components[i].id === 'fd6phrb801rc') {
        appStatus = response.components[i].status;
        this.applicationStatus(appStatus);
        break;
      }
    }
    return appStatus;
  }

  /**
   * Gets the maintenance status of the application
   */
  getStatusMaintenance() {
    return this.appRunningStatus;
  }

  /**
   * Handles the application status and navigates to the maintenance page if needed
   * @param data The status of the application
   */
  applicationStatus(data: string): void {
    if (data == 'under_maintenance') {
      this.router.navigate(['/auth/under-maintenance']);
    }
  }
}
