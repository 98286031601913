import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validator function to check for password strength. The password must contain at least:
 * - One uppercase letter
 * - One lowercase letter
 * - One numeric character
 * - One special character from the set `!@#$&*`
 *
 * @returns {ValidatorFn} A validator function that checks for password strength.
 */
export const PasswordStrengthValidator = (): ValidatorFn => {
  // validator for password strength need to be with one capital case, one numeric and one special character
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const hasUpperCase = /[A-Z]+/.test(value);

    const hasLowerCase = /[a-z]+/.test(value);

    const hasNumeric = /[0-9]+/.test(value);

    const specialCharacter = /[!@#$&*]+/.test(value);

    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && specialCharacter;

    return !passwordValid ? { pattern: true } : null;
  };
};
