import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Directive to handle image loading errors by providing a fallback image.
 *
 * This directive listens for the `error` event on `img` elements and sets a fallback image
 * if the original image fails to load. It is applied to `img` elements with the `onErrorImg` attribute selector.
 */
@Directive({
  selector: 'img[onErrorImg]',
})
export class OnErrorImgDirective {
  /**
   * The fallback image URL to use when the original image fails to load.
   *
   * @example "assets/images/no-preview.png"
   */
  @Input() onErrorImg!: string;

  /**
   * Creates an instance of the OnErrorImgDirective.
   *
   * @param el Provides direct access to the DOM element.
   */
  constructor(private el: ElementRef) {}

  /**
   * Listens to the `error` event on the `img` element and sets a fallback image if the original image fails to load.
   */
  @HostListener('error')
  onError() {
    const element = <HTMLImageElement>this.el.nativeElement;
    element.src = this.onErrorImg || 'assets/images/no-preview.png';
  }
}
