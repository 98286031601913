<div class="auth-wrapper p-3">
  <div class="logo text-center">
    <img src="assets/images/logo/medocity_logo.png" alt="Medocity logo" />
  </div>
  <div class="auth-container bg-white radius-md">
    <h1>{{ 'forgotPassword' | translate }}</h1>
    <p class="my-3">
      {{ 'resetPasswordMessage' | translate }}
    </p>
    <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
      <div class="mb-3">
        <label for="userName" class="form-label">{{ 'username' | translate }}</label>
        <input
          type="email"
          id="userName"
          class="form-control"
          formControlName="userName"
          [ngClass]="{
            'is-invalid':
              forgotPasswordFormControls.userName.invalid && (submitted || forgotPasswordFormControls.userName.touched)
          }"
        />
        @if (
          forgotPasswordFormControls.userName.invalid && (submitted || forgotPasswordFormControls.userName.touched)
        ) {
          <div class="invalid-feedback">
            @if (forgotPasswordFormControls.userName.errors?.['required']) {
              <div>Username is required.</div>
            }
            @if (forgotPasswordFormControls.userName.errors?.['email']) {
              <div>Username must be a valid email.</div>
            }
          </div>
        }
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary px-5">{{ 'submit' | translate }}</button>
      </div>
      <p class="text-center pt-3 mb-0">
        <a routerLink="/login">{{ 'backToSignIn' | translate }}</a>
      </p>
    </form>
  </div>
</div>
<app-footer></app-footer>
