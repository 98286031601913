import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

/**
 * Custom validator directive to ensure that the input does not start or end with whitespace and does not contain
 * consecutive whitespace characters.
 */
@Directive({
  selector: '[spaceRemoveDirective]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CustomPatternValidatorDirective,
      multi: true,
    },
  ],
})
export class CustomPatternValidatorDirective implements Validator {
  /**
   * Validates the input value to ensure it conforms to the specified pattern.
   *
   * The value must not start or end with whitespace and must not contain consecutive whitespace characters.
   *
   * @param control The form control to validate.
   * @returns An object with a `customValidator` property if validation fails, otherwise `null`.
   */
  validate(control: AbstractControl): { [key: string]: any } | null {
    const regex = /^(\s+\S+\s*)*(?!\s).*$/;

    if (control.value && !regex.test(control.value)) {
      return { customValidator: true };
    }

    return null;
  }
}
