import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { NavMenuDropDownComponent } from '../nav-menu-drop-down/nav-menu-drop-down.component';

/**
 * Component to render the header of the application.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  /** Flag to indicate if the navigation menu is open */
  menu = false;

  /**
   * Constructor to inject dependencies
   * @param dialog MatDialog service to open dialogs
   * @param authService Authentication service to handle user authentication
   * @param document Document object to manipulate DOM
   */
  constructor(
    public dialog: MatDialog,
    private authService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document,
  ) {}
  /**
   * Getter to retrieve user details from the authentication service
   */
  get userDetails() {
    return this.authService.getUserDetails();
  }

  /**
   * OnInit lifecycle hook to add a CSS class to the document body
   */
  ngOnInit(): void {
    // this.document.body.classList.add('nav-closed');
  }

  /**
   * Signs out the user by calling the authentication service
   */
  signOut() {
    this.authService.logout().subscribe({
      next: (response) => {
        if (response) {
          this.authService.signOut();
        }
      },
      error: (error) => {
        if (error) {
          this.authService.signOut();
        }
      },
    });
  }

  /**
   * Toggles the navigation menu's open/closed state by modifying CSS classes on the document body
   */
  // toggleNav() {
  //   if (this.document) {
  //     this.document.body.classList.toggle('nav-open');
  //     this.document.body.classList.toggle('nav-closed');
  //   }
  // }

  /**
   * Opens the navigation menu drop-down dialog
   */
  openNavMenuDroDownDialog() {
    this.menu = true;
    const dialogRef = this.dialog.open(NavMenuDropDownComponent, {
      panelClass: 'main-navigation-dialog',
      position: { top: '60px', left: '24px' },
      backdropClass: 'header-menu-backdrop',
    });
    dialogRef.afterClosed().subscribe(() => (this.menu = false));
  }
}
