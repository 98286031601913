import { Component } from '@angular/core';
/**
 * Component to display a maintenance page.
 * This component is used to inform users that the application is currently under maintenance.
 */
@Component({
  selector: 'app-under-maintenance',
  standalone: true,
  imports: [],
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss'],
})
export class UnderMaintenanceComponent {}
