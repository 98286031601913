import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { NotSignedInGuard } from './shared/guards/not-signed-in.guard';

const routes: Routes = [
  {
    path: '', //this wil load auth module if no route found
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'forgot-password',
    redirectTo: 'auth/forgot-password',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    canActivate: [NotSignedInGuard],
    loadChildren: () => import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/clients', pathMatch: 'full' },
      {
        path: 'clients',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'selected-client',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/client-wrapper/client-wrapper.module').then((m) => m.ClientWrapperModule),
      },
      {
        path: 'users',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'groups',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/groups/groups.module').then((m) => m.GroupsModule),
      },
      {
        path: 'roles',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'dashboards',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/custom-dashboard/custom-dashboard.module').then((m) => m.CustomDashboardModule),
      },
      {
        path: 'clinical-trials',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/clinical-trials/clinical-trials.module').then((m) => m.ClinicalTrialsModule),
      },
      {
        path: 'reach-trials',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/reach-trial/reach-trial.module').then((m) => m.ReachTrialModule),
      },
      {
        path: 'clinical-sites',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/clinical-sites/clinical-sites.module').then((m) => m.ClinicalSitesModule),
      },
      {
        path: 'resources',
        canLoad: [AuthGuard],
        loadComponent: () =>
          import('./modules/resources/components/resource/resource.component').then((m) => m.ResourceComponent),
      },
      {
        path: 'goals-tasks',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/goals-tasks/goals-tasks.module').then((m) => m.GoalsTasksModule),
      },
      {
        path: 'disclaimers',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/disclaimers/disclaimer.module').then((m) => m.DisclaimerModule),
      },
      {
        path: 'message-template',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/communication/communication.module').then((m) => m.CommunicationModule),
      },
      {
        path: 'settings',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        // ./reach-trial/reach-trial.module
        path: 'audit-trails',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/audit-trial/audit-trial.module').then((m) => m.AuditTrialModule),
      },
      {
        path: 'widget',
        canLoad: [AuthGuard],
        loadChildren: () => import('./modules/widget/widget.module').then((m) => m.WidgetModule),
      },
    ],
  },
  { path: 'error', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
