import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { ClientsService } from 'src/app/modules/clients/clients.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DirectiveModule } from '../../modules/directive/directive.module';

/**
 * Component to render a navigation menu drop-down.
 */
@Component({
  selector: 'app-nav-menu-drop-down',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, FeatherModule, MatDialogModule, MatMenuModule, DirectiveModule, NgClass],
  templateUrl: './nav-menu-drop-down.component.html',
  styleUrl: './nav-menu-drop-down.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuDropDownComponent implements OnInit {
  /** Injected shared service instance */
  #sharedService = inject(SharedService);
  /** Injected clients service instance */
  #clientsService = inject(ClientsService);
  /** Menu items to be displayed in the drop-down */
  menuItems;
  /** Client ID fetched from the route query parameters */
  clientId = '';
  /** Observable for basic client details */
  basicClientDetails = this.#clientsService.basicClientDetails$;

  /**
   * Constructor to inject dependencies and initialize client ID from route parameters
   * @param activatedRoute ActivatedRoute service to access route information
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  /**
   * OnInit lifecycle hook to initialize menu items based on client ID
   */
  ngOnInit(): void {
    this.clientId = this.getClientId();
    this.menuItems = this.clientId ? this.#sharedService.getClientMenu() : this.#sharedService.getSystemMenu();
  }
  /**
   * Get client ID from the url params.
   */
  getClientId() {
    let clientId = this.activatedRoute.snapshot.queryParamMap.get('clientId') || '';
    if (!clientId) {
      const queryParams = new URLSearchParams(this.router.url);
      try {
        clientId = queryParams?.get('clientId') || '';
      } catch {
        clientId = '';
      }
    }
    return clientId;
  }
}

/**
 * Interface representing a menu item in the application.
 */
export interface Menu {
  /**
   * The type of the menu item
   */
  type?: string;

  /**
   * The label displayed for the menu item.
   */
  label: string;

  /**
   * The icon associated with the menu item
   */
  icon: string;

  /**
   * The router link for the menu item
   */
  routerLink: string;

  /**
   * Indicates whether the menu item is a separator.
   */
  isSeparator?: boolean;

  /**
   * Sub-items of the menu item. This property is optional and allows
   * the menu item to have a nested structure with child menu items.
   */
  items?: Menu[];
}
