import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for transforming location-based labels based on the country and type.
 *
 * This pipe modifies labels such as 'state', 'zip', and 'city' according to the
 * specified country. For the United States of America, it returns the provided name.
 * For other countries, it returns a corresponding generic term.
 */
@Pipe({
  name: 'labels',
})
export class labelsPipe implements PipeTransform {
  value!: string;

  /**
   * Transforms a location-based label based on the provided country and type.
   *
   * @param name The name of the location element (e.g., 'State', 'ZIP Code', 'City').
   * @param country The country name to determine if the label should be specific or generic.
   * @param type The type of location element, which determines how the name is transformed.
   *
   * @returns A string representing the transformed label based on the country and type.
   * - For 'state', returns 'State' for the United States of America and 'Province' for other countries.
   * - For 'zip', returns 'ZIP Code' for the United States of America and 'PostalCode' for other countries.
   * - For 'city', returns 'City' for the United States of America and 'Municipality' for other countries.
   */
  transform(name: string, country: string, type: string) {
    if (type == 'state') {
      this.value = country == 'United States of America' ? name : 'Province';
    } else if (type == 'zip') {
      this.value = country == 'United States of America' ? name : 'PostalCode';
    } else if (type == 'city') {
      this.value = country == 'United States of America' ? name : 'Municipality ';
    }
    return this.value;
  }
}
