import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { CustomHttpInterceptor, DEFAULT_TIMEOUT } from './interceptors/custom-http.interceptor';
import { TranslatePipe } from './modules/pipes/translate/translate.pipe';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
  ],
  exports: [
    ToastrModule,
  ],
  providers: [
    DatePipe,
    TranslatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: DEFAULT_TIMEOUT, useValue: 300000 },
    { provide: 'API_BASE', useValue: environment.webAPI.apiUrl },
    { provide: 'translateURL', useValue: environment.webAPI.translateURL },
    { provide: 'API_VERSION', useValue: environment.webAPI.version },
    { provide: 'v2_API_BASE', useValue: environment.webAPI.cerebellumURL },
    { provide: 'AUTH_API_BASE', useValue: environment.webAPI.authUrl }
  ],
})
export class SharedModule {}
