import { GenericTable } from '../interfaces/generic-table';

/**
 * Utility class providing various helper methods for date manipulation, validation, and string formatting.
 */
export class Utils {
  /** Creates a `GenericTable` object with the provided values.
   * @param {any} value - The value to be stored in the table.
   * @param {string} [routeUrl] - Optional route URL associated with the table.
   * @param {object} [queryParams] - Optional query parameters associated with the table.
   *
   * @returns {GenericTable} An object conforming to the GenericTable interface.
   */
  public static createGenericTableKeysData(value, routeUrl?: string, queryParams?: object, fragment?: string) {
    let tableViewData: GenericTable;
    // eslint-disable-next-line prefer-const
    tableViewData = {
      value: value ? value : '',
      routeUrl: routeUrl ? routeUrl : '',
      queryParams: queryParams ? queryParams : {},
      fragment: fragment,
    };
    return tableViewData;
  }

  /**
   * Returns the keys of an object as an array.
   *
   * @param {Record<string, string>} obj - The object whose keys are to be retrieved.
   *
   * @returns {string[]} An array of keys from the object.
   */
  public static getObjectKeys(obj: Record<string, string>) {
    return Object.keys(obj);
  }

  /**
   * Checks if a value is a string.
   *
   * @param {unknown} value - The value to be checked.
   *
   * @returns {boolean} True if the value is a string, false otherwise.
   */
  public static isString(value: unknown) {
    return Object.prototype.toString.call(value) === '[object String]';
  }

  /**
   * Converts a string to camel case.
   *
   * @param {string} value - The string to be camelized.
   *
   * @returns {string} The camelized string.
   */
  public static camelize(value: string) {
    let camelizeString = value;
    if (this.isString(value)) {
      camelizeString = value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }
    return camelizeString;
  }

  /**
   * Capitalizes the first letter of each word in a string.
   *
   * @param {string} str - The string to be capitalized.
   *
   * @returns {string} The capitalized string.
   */
  public static capitalizeFirstLetter(str) {
    // Split the string into words
    const words = str.split(' ');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the capitalized words back into a string
    const capitalizedStr = capitalizedWords.join(' ');

    return capitalizedStr;
  }

  /**
   * Checks if a string is a valid URL.
   *
   * @param {string} url - The string to be checked.
   *
   * @returns {boolean} True if the string is a valid URL, false otherwise.
   */
  public static isValidUrl(url: string) {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  }

  /**
   * Converts a date from UTC to local time.
   *
   * @param {Date | string | null} date - The date to be converted.
   *
   * @returns {Date | string} The converted date in local time, or an empty string if the date is null.
   */
  public static convertUTCToLocal(date: Date | string | null) {
    return date ? new Date(date) : '';
  }

  /**
   * Converts a date from local time to UTC.
   *
   * @param {Date | string | null} date - The date to be converted.
   *
   * @returns {string | undefined} The converted date in ISO format, or undefined if the date is invalid.
   */
  public static convertLocalToUTC(date: Date | string | null) {
    let utcDate;
    if (this.isValidDate(date)) {
      utcDate = this.getISODate(date);
    }
    return utcDate;
  }

  /**
   * Converts a date to ISO format.
   *
   * @param {Date | string | null} date - The date to be converted.
   *
   * @returns {string | null} The date in ISO format, or the original date if it is invalid.
   */
  public static getISODate(date: Date | string | null) {
    return date && this.isValidDate(date) ? new Date(date).toISOString() : date;
  }

  /**
   * Checks if a date is valid.
   *
   * @param {Date | string | null} date - The date to be checked.
   *
   * @returns {boolean} True if the date is valid, false otherwise.
   */
  public static isValidDate(date: Date | string | null) {
    const nDateO = date ? new Date(date) : null;
    return date && nDateO && nDateO instanceof Date && isFinite(nDateO.getTime()) ? true : false;
  }

  /**
   * Checks if two dates are the same.
   *
   * @param {Date} start - The start date.
   * @param {Date} end - The end date.
   *
   * @returns {boolean} True if the dates are the same, false otherwise.
   */
  public static isSameDate(start: Date, end: Date) {
    const startDateO = this.isValidDate(start) ? new Date(start.setHours(0, 0, 0, 0)) : null;
    const endDateO = this.isValidDate(start) ? new Date(end.setHours(0, 0, 0, 0)) : null;
    if (startDateO && endDateO) {
      return startDateO.getTime() === endDateO.getTime() ? true : false;
    } else {
      return false;
    }
  }

  /**
   * Checks if a date is in the past.
   *
   * @param {Date} [date=new Date()] - The date to be checked.
   *
   * @returns {boolean} True if the date is in the past, false otherwise.
   */
  public static isPastDate(date: Date = new Date()) {
    const dateO = this.isValidDate(date)
      ? new Date(date.setHours(0, 0, 0, 0))
      : new Date(new Date().setHours(0, 0, 0, 0));
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    if (dateO && today) {
      return dateO.getTime() < today.getTime() ? true : false;
    } else {
      return false;
    }
  }

  /**
   * Rounds a date to the nearest specified duration in minutes.
   *
   * @param {Date} [date=new Date()] - The date to be rounded.
   * @param {number} duration - The duration in minutes to round to.
   *
   * @returns {Date} The rounded date.
   */
  public static getNearestRoundedDateByDuration(date: Date = new Date(), duration: number) {
    const nDateO = this.isValidDate(date) ? new Date(date) : new Date();
    const coff = 1000 * 60 * duration;
    return new Date(Math.ceil(nDateO.getTime() / coff) * coff);
  }

  /**
   * Adds a specified number of minutes to a date.
   *
   * @param {Date} [date=new Date()] - The date to add minutes to.
   * @param {number} duration - The number of minutes to add.
   *
   * @returns {Date} The new date with the minutes added.
   */
  public static addMinutes(date: Date = new Date(), duration: number) {
    const nDateO = Utils.isValidDate(date) ? new Date(date) : new Date();
    return new Date(new Date(nDateO).setMinutes(new Date(nDateO).getMinutes() + duration));
  }

  /**
   * Gets the previous date from a given date.
   *
   * @param {any} date - The date to get the previous date from.
   *
   * @returns {Date} The previous date.
   */
  public static getPreviousDate(date: Date | string) {
    let nDateO = new Date(date);
    if (this.isValidDate(nDateO)) {
      nDateO.setDate(nDateO.getDate() - 1);
      nDateO = new Date(nDateO);
    }
    return nDateO;
  }

  /**
   * Gets the next date from a given date.
   *
   * @param {any} date - The date to get the next date from.
   *
   * @returns {Date} The next date.
   */
  public static getNextDate(date: Date | string) {
    let nDateO = new Date(date);
    if (this.isValidDate(nDateO)) {
      nDateO.setDate(nDateO.getDate() + 1);
      nDateO = new Date(nDateO);
    }
    return nDateO;
  }

  /**
   * Gets the number of days between two dates.
   *
   * @param {string} from - The start date.
   * @param {string} to - The end date.
   *
   * @returns {number} The number of days between the two dates.
   */
  public static getNoOfDays(from: string, to: string) {
    let noOfDays = 0;
    if (from) {
      const toDate = to ? new Date(new Date(to).setHours(0, 0, 0, 0)) : new Date(new Date().setHours(0, 0, 0, 0));
      const fromDate = new Date(new Date(from).setHours(0, 0, 0, 0));
      const oneDayInMS = 1000 * 60 * 60 * 24;
      const dateDiff = toDate.getTime() - fromDate.getTime();
      noOfDays = dateDiff / oneDayInMS;
    }
    return noOfDays > 0 ? noOfDays : 0;
  }

  /**
   * Gets the start of the month for a given date.
   *
   * @param {Date} date - The date to get the start of the month for.
   *
   * @returns {Date | undefined} The start of the month date, or undefined if the date is invalid.
   */
  public static startOfMonth(date: Date) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  /**
   * Gets the end of the month for a given date.
   *
   * @param {Date} date - The date to get the end of the month for.
   *
   * @returns {Date | undefined} The end of the month date, or undefined if the date is invalid.
   */
  public static endOfMonth(date: Date) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  /**
   * Gets the start of the day for a given date.
   *
   * @param {Date} [date=new Date()] - The date to get the start of the day for.
   *
   * @returns {Date | undefined} The start of the day date, or undefined if the date is invalid.
   */
  public static startOfDate(date: Date = new Date()) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  }

  /**
   * Gets the end of the day for a given date.
   *
   * @param {Date} [date=new Date()] - The date to get the end of the day for.
   *
   * @returns {Date | undefined} The end of the day date, or undefined if the date is invalid.
   */
  public static endOfDate(date: Date = new Date()) {
    if (!this.isValidDate(date)) {
      return;
    }
    return new Date(new Date(date).setHours(23, 59, 59, 999));
  }

  /**
   * Creates a deep copy of the provided data.
   *
   * @param {data} - The data to be copied.
   *
   * @returns {data} The deep copied data.
   */
  public static copy(data) {
    if (data) {
      try {
        return structuredClone(data);
      } catch {
        return data;
      }
    }
    return data;
  }
  /**
   * Creates a deep copy of the provided data.
   *
   * @param {obj} - The data to be copied.
   *
   * @returns {clone} The deep copied data.
   */
  public static deepCopy<T>(data, seen = new WeakMap()): T {
    if (!data) {
      return data;
    }
    // Check if the object is null or not an object (e.g., primitive types)
    if (data === null || typeof data !== 'object') {
      return data;
    }
    // Handle circular references
    if (seen.has(data)) {
      return seen.get(data);
    }
    // Handle special objects
    let clone;
    if (data instanceof Date) {
      clone = new Date(data); // Clone Date objects
    } else if (data instanceof Map) {
      clone = new Map();
      data.forEach((value, key) => {
        clone.set(this.deepCopy(key, seen), this.deepCopy(value, seen));
      });
    } else if (data instanceof Set) {
      clone = new Set();
      data.forEach((value) => {
        clone.add(this.deepCopy(value, seen));
      });
    } else if (typeof data === 'function') {
      clone = data.bind({}); // Clone functions (bind to a new context)
    } else {
      clone = Array.isArray(data) ? [] : Object.create(Object.getPrototypeOf(data));
    }
    // Store the cloned object in the WeakMap
    seen.set(data, clone);
    // Recursively clone each property
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        clone[key] = this.deepCopy(data[key], seen);
      }
    }
    return clone;
  }
  /**
   * Removes HTML tags from the given text.
   * @param text - The text containing HTML tags.
   * @param tags - The tags need to remove.
   * @returns The text with HTML tags removed.
   */
  public static removeHtmlTags(text: string, tags?: string[]) {
    if (text && Utils.isString(text)) {
      try {
        // Parse the input HTML string into a DOM Document
        const html = new DOMParser().parseFromString(text, 'text/html');
        // Loop through specific tags to remove
        tags?.forEach((tag) => {
          // Use querySelectorAll to find all instances of the tag
          const elements = html.querySelectorAll(tag);
          // Replace the element with its inner HTML content
          elements?.forEach((element) => {
            if (element.parentNode) {
              element.parentNode?.replaceChild(html.createTextNode(element.innerHTML), element);
            }
          });
        });
        return html.body.textContent || html.documentElement.innerText || '';
      } catch (error) {
        console.error(error);
      }
    }
    return text;
  }
}
