<div class="system-tabs">
  <mat-tab-group
    (selectedIndexChange)="selectedIndex($event)"
    [(selectedIndex)]="selectedTabIndex"
    animationDuration="600ms"
  >
    @for (item of tabsData; track item) {
      <mat-tab [label]="item.label">
        <ng-container [ngTemplateOutlet]="widgetComponent"></ng-container>
      </mat-tab>
    }
  </mat-tab-group>
  <div class="conatiner">
    <ng-template matStepContent #widgetComponent></ng-template>
  </div>
</div>
