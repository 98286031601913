import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { ToastrModule } from 'ngx-toastr';
import { labelsPipe } from 'src/app/shared/pipes/labels.pipe';
import { environment } from 'src/environments/environment';
import { CommunicationSearchComponent } from '../modules/communication/components/communication-search/communication-search.component';
import { CommunicationViewComponent } from '../modules/communication/components/communication/communication-view/communication-view.component';
import { CheckboxRadioCardComponent } from './components/checkbox-radio-card/checkbox-radio-card.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DobComponent } from './components/dob/dob.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatTabGroupComponent } from './components/mat-tab-group/mat-tab-group.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ThankyouSubmissionDialogComponent } from './components/thankyou-submission-dialog/thankyou-submission-dialog.component';
import { CustomPatternValidatorDirective } from './directives/custom-pattern-validator.directive';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { TrimDirective } from './directives/trim.directive';
import { CustomHttpInterceptor, DEFAULT_TIMEOUT } from './interceptors/custom-http.interceptor';
import { DirectiveModule } from './modules/directive/directive.module';
import { PipesModule } from './modules/pipes/pipes.module';
import { TranslatePipe } from './modules/pipes/translate/translate.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { UnitPreferencePipe } from './pipes/unit-preference.pipe';
import { UserGrpTypePipe } from './pipes/user-grp-type.pipe';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    MatRadioModule,
    MatSelectModule,
    MatNativeDateModule,
    MatMenuModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatStepperModule,
    MatSliderModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    DragDropModule,
    CdkTreeModule,
    NgbModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    PipesModule,
    DirectiveModule,
    FeatherModule.pick(allIcons),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
    // stand alone components import
    PaginationComponent,
    MatTabGroupComponent,
    UserGrpTypePipe,
  ],
  exports: [
    HttpClientModule,
    ToastrModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    NgbModule,
    CommonModule,
    OrderByPipe,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    MatRadioModule,
    MatSelectModule,
    MatNativeDateModule,
    MatMenuModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatSliderModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    DragDropModule,
    CdkTreeModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    FeatherModule,
    labelsPipe,
    UnitPreferencePipe,
    PipesModule,
    DirectiveModule,
    DobComponent,
    CheckboxRadioCardComponent,
    DigitOnlyDirective,
    TrimDirective,
    OnlyNumberDirective,
    CustomPatternValidatorDirective,
    FooterComponent,
    MatTabGroupComponent,
    UserGrpTypePipe,
  ],
  providers: [
    DatePipe,
    TranslatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: DEFAULT_TIMEOUT, useValue: 300000 },
    { provide: 'API_BASE', useValue: environment.webAPI.apiUrl },
    { provide: 'translateURL', useValue: environment.webAPI.translateURL },
    { provide: 'API_VERSION', useValue: environment.webAPI.version },
    { provide: 'v2_API_BASE', useValue: environment.webAPI.cerebellumURL },
  ],
  declarations: [
    OrderByPipe,
    ConfirmationDialogComponent,
    CheckboxRadioCardComponent,
    CommunicationSearchComponent,
    CommunicationViewComponent,
    labelsPipe,
    UnitPreferencePipe,
    ThankyouSubmissionDialogComponent,
    FooterComponent,
  ],
})
export class SharedModule {}
