import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { HeaderComponent } from './shared/components/header/header.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { TranslateService } from './shared/services/translate.service';
import { SharedModule } from './shared/shared.module';

/**
 * Factory function for configuring the TranslateService.
 * This function is executed during the application initialization phase.
 * @param {TranslateService} service - The translation service used for configuring the default language.
 * @returns {Function} A function that returns a promise resolved when the configuration is complete.
 */
export const setupTranslateFactory = (service: TranslateService) => () => service.use('en');

@NgModule({
  declarations: [AppComponent, HeaderComponent, LayoutComponent, SpinnerComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    AuthenticationModule,
    MatSliderModule,
    FormsModule,
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
