<div class="auth-wrapper p-3">
  <div class="logo text-center">
    <img src="assets/images/logo/medocity_logo.png" alt="Medocity logo" />
  </div>
  <div class="auth-container">
    <h1>{{ 'updatePassword' | translate }}</h1>
    <form [formGroup]="forgotPasswordForm" (submit)="submit()">
      <div class="mb-3">
        <label for="password" class="form-label">{{ 'password' | translate }}</label>
        <input
          type="password"
          id="password"
          class="form-control"
          formControlName="password"
          [class]="{
            'is-invalid':
              forgotPasswordForm.get('password')?.invalid && (submitted || forgotPasswordForm.get('password')?.touched)
          }"
        />
        @if (
          forgotPasswordForm.get('password')?.invalid && (submitted || forgotPasswordForm.get('password')?.touched)
        ) {
          <div class="invalid-feedback">
            @if (forgotPasswordForm.get('password')?.errors?.['required']) {
              <div>
                {{ 'passwordIsRequired' | translate }}
              </div>
            }
            @if (
              forgotPasswordForm.get('password')?.errors?.['minlength'] ||
              forgotPasswordForm.get('password')?.errors?.['pattern']
            ) {
              <div>
                {{ 'passwordStrengthValidation' | translate }}
              </div>
            }
          </div>
        }
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">{{ 'confirmPassword' | translate }}</label>
        <input
          type="password"
          id="confirmPassword"
          class="form-control"
          formControlName="confirmPassword"
          [class]="{
            'is-invalid':
              forgotPasswordForm.get('confirmPassword')?.invalid &&
              (submitted || forgotPasswordForm.get('confirmPassword')?.touched)
          }"
        />
        @if (
          forgotPasswordForm.get('confirmPassword')?.invalid &&
          (submitted || forgotPasswordForm.get('confirmPassword')?.touched)
        ) {
          <div class="invalid-feedback">
            @if (forgotPasswordForm.get('confirmPassword')?.errors?.['required']) {
              <div>
                {{ 'confirmPasswordIsRequired' | translate }}
              </div>
            }
            @if (forgotPasswordForm.get('confirmPassword')?.errors?.['mustMatch']) {
              <div>
                {{ 'passwordAndConfirmPasswordMustBeMatched' | translate }}
              </div>
            }
          </div>
        }
        <p class="info-small">
          {{ 'passwordStrengthValidation' | translate }}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary px-5">{{ 'submit' | translate }}</button>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>
