import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';

import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { environment } from 'src/environments/environment';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent],
  imports: [SharedModule, AuthenticationRoutingModule],
  providers: [{ provide: 'AUTH_API_BASE', useValue: environment.webAPI.authUrl }],
})
export class AuthenticationModule {}
