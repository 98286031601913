import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
/**
 * A route guard service that ensures a user is authenticated before allowing access to routes.
 * This guard is used to protect routes and prevent unauthorized access.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  /** Creates an instance of the class.
   * @param authService - Service for managing authentication and authorization.
   * @param router - Angular router service for navigation.
   */
  constructor(
    private authService: AuthenticationService,
  ) {}
  /**
   * Checks if a route can be activated based on the user's authentication status.
   * Redirects to the login page if the user is not authorized.
   *
   * @param route - The activated route snapshot.
   * @param state - The router state snapshot.
   *
   * @returns Observable, Promise, boolean, or UrlTree indicating whether the route can be activated.
   */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardCallBack();
  }
  /**
   * Checks if a route can be loaded based on the user's authentication status.
   * Redirects to the login page if the user is not authorized.
   *
   * @param route - The route configuration.
   * @param segments - The URL segments of the route.
   *
   * @returns Observable, Promise, boolean, or UrlTree indicating whether the route can be loaded.
   */
  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.guardCallBack();
  }
  /**
   * Callback method that determines whether the user is authorized.
   * Redirects to the login page if the user is not authorized.
   *
   * @returns boolean indicating if the user is authorized (true) or not (false).
   */
  guardCallBack() {
    if (this.authService.isAuthorized()) {
      if (!this.authService.getAccessAfterLoggedIn()) {
        this.authService.signOut();
        return false;
      }
      return true;
    } else {
      this.authService.signOut();
      return false;
    }
  }
}
