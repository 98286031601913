<div class="form-group button-file">
  <label *ngIf="!showRequiredMark && label" class="form-label">{{ label ? label : '' }}</label>
  <label *ngIf="showRequiredMark && label" required class="form-label">{{ label ? label : '' }}</label>
  <div class="d-flex">
    <div class="form-control label_file {{ errorClassName }}">
      {{ name || file?.name || fileUrl || '' }}
      <span class="file-placeholder">{{ placeholder }}</span>
    </div>
    <div class="btn btn-primary ms-2">
      <input type="file" id="file" [accept]="allowFileType" (change)="uploadFile($event)" [disabled]="disabled" />
      <span class="file-text pointer" [class]="disabled ? 'disabled' : ''">Choose File</span>
    </div>
  </div>
  <div class="selected-file" *ngIf="fileUrl && isValidUrl(fileUrl)">
    @if (showRemoveFileIcon) {
      <i-feather name="x-circle" class="feather-20 remove-logo" (click)="removeFile()"></i-feather>
    }
    <img
      [src]="getFileIcon(fileUrl)"
      alt="alt"
      *ngIf="getFileIcon(fileUrl)"
      (click)="openPreviewDialog({ url: fileUrl, v2: apiVersion && apiVersion === 'v2' ? true : false })"
    />
  </div>
</div>
<div *ngIf="sizeError" class="alert">{{ sizeError }}</div>
