import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
/**
 * Component for viewing communication details within a dialog.
 */
@Component({
  selector: 'app-communication-view',
  templateUrl: './communication-view.component.html',
  styleUrls: ['./communication-view.component.scss'],
})
export class CommunicationViewComponent implements OnInit {
  /** Holds the communication details passed to the dialog. */
  public communicationDetails: any;

  /**
   * Constructor for the CommunicationViewComponent.
   * @param data - Data injected into the dialog, including communication details.
   * @param dialog - Reference to the MatDialog instance, used for closing the dialog.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<CommunicationViewComponent>,
  ) {
    this.viewCommunicationGroupTemplate();
  }

  /**
   * Currently, it does not perform any operations.
   */
  ngOnInit(): void {}

  /**
   * Initializes communication details from the data injected into the dialog.
   * This method is called in the constructor to set up the component with the provided data.
   */
  public viewCommunicationGroupTemplate(): void {
    this.communicationDetails = this.data.data;
  }

  /**
   * Closes the dialog and passes the current data back to the caller.
   */
  public popupClose(): void {
    this.dialog.close(this.data);
  }
}
