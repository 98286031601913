import { Component } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { AuthServiceService } from '../services/auth-service.service';

/**
 * Component to display a maintenance page.
 * This component is used to inform users that the application is currently under maintenance.
 */
@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss'],
})
export class UnderMaintenanceComponent {}
