import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/shared/validators/must-match.validators';
import { PasswordStrengthValidator } from 'src/app/shared/validators/password-strength.validators';
import { AuthenticationService } from '../../authentication.service';
import { FeatherModule } from 'angular-feather';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { TranslatePipe } from 'src/app/shared/modules/pipes/translate/translate.pipe';

/**
 * Component for changing user password.
 */
@Component({
  selector: 'app-change-password',
  standalone: true,
  imports:[ReactiveFormsModule, FormsModule, FeatherModule, FooterComponent, TranslatePipe],
  providers:[TranslatePipe],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  /** Form group for the change password form. */
  forgotPasswordForm!: FormGroup;

  /** Flag to track if form has been submitted. */
  submitted = false;

  /**
   *  Represents a component/service constructor.
   * @param {FormBuilder} fb - Injected FormBuilder instance.
   * @param {AuthenticationService} authService - Injected AuthServiceService instance.
   * @param {Router} router - Injected Router instance.
   * @param {ToastrService} toasterService - Injected ToastrService instance.
   */
  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private toasterService: ToastrService,
  ) {}

  /**
   * Initializes the component and sets up the change password form.
   */
  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group(
      {
        password: ['', [Validators.required, PasswordStrengthValidator()]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      },
    );
  }

  /**
   * Submits the change password form.
   */
  submit() {
    this.submitted = true;
    if (this.forgotPasswordForm.valid && this.submitted) {
      const body = { forced: true, newPassword: this.forgotPasswordForm.get('password')?.value };
      this.authService.updatePassword(body).subscribe(() => {
        this.toasterService.success('Password has been updated', 'Success');
        this.router.navigate(['auth/login']);
      });
    }
  }
}
