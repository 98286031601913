import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'src/app/shared/services/translate.service';
/**
 * An Angular pipe that translates a given key into its corresponding value from the translation service.
 * Utilizes the `TranslateService` to fetch the translation for the provided key.
 */
@Pipe({
  name: 'translate',
  pure: false,
  standalone: true,
})
export class TranslatePipe implements PipeTransform {
  /**
   * @param translate - The `TranslateService` used to fetch translations for the provided keys.
   */
  constructor(private translate: TranslateService) {}
  /**
   * Transforms the provided key into its corresponding translation value.
   *
   * @param key - The key for which the translation is to be fetched. It is expected to be a string that maps to a value in the translation service.
   * @returns The translated value corresponding to the key if it exists, otherwise returns the key itself.
   */
  transform(key: any): any {
    return this.translate.data[key] || key;
  }
}
