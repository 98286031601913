import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

/**
 * Component for managing and validating a date of birth (DOB) input.
 * Provides a form interface for users to input month, day, and year of birth.
 * Emits updated date values and validation errors.
 */
@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.scss'],
})
export class DobComponent implements OnChanges {
  /** Input date of birth in a date format */
  @Input() dob;
  /** Month part of the date of birth */
  mm = '';
  /** Day part of the date of birth */
  dd = '';
  /** Year part of the date of birth */
  yyyy = '';
  /** Formatted date of birth string */
  updateDate = '';
  /** Form group for the date of birth input fields */
  form: FormGroup;
  /** Flag indicating if there is a validation error */
  errMsg = false;
  /** Event emitted when the date of birth is updated */
  @Output() updateDob: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitted when the month is changed */
  @Output() month: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitted when the day is changed */
  @Output() date: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitted when the year is changed */
  @Output() year: EventEmitter<string> = new EventEmitter<string>();
  /** Event emitted when there is a validation error */
  @Output() errorCode: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Flag indicating if there is a final validation error */
  finalError = false;

  /** Creates an instance of the class.
   * @param formBuilder FormBuilder service for creating the form group
   * @param datePipe DatePipe service for date transformations
   */
  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
  ) {
    this.form = this.formBuilder.group({
      dobMonth: ['', [Validators.required]],
      dobDay: ['', [Validators.required]],
      dobYear: ['', [Validators.required, Validators.pattern('(19[0-9][0-9]|20[0-9][0-9]|2010)')]],
    });
  }

  /**
   * Lifecycle hook that is called when input properties change.
   * Updates the form fields and emits the updated date of birth.
   */
  ngOnChanges() {
    if (this.dob) {
      this.mm = this.datePipe.transform(this.dob, 'MM') || '';
      this.dd = this.datePipe.transform(this.dob, 'dd') || '';
      this.yyyy = this.datePipe.transform(this.dob, 'yyyy') || '';
      this.form.patchValue({
        dobMonth: this.mm,
        dobDay: this.dd,
        dobYear: this.yyyy,
      });
      this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    }
  }
  /**
   * Handles month change and updates the date of birth.
   * @param mm Month value
   */
  changeMonth(mm) {
    this.mm = ('0' + mm).slice(-2);
    this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    this.updateDob.emit(this.updateDate);
  }
  /**
   * Handles day change and updates the date of birth.
   * @param dd Day value
   */
  changeDate(dd) {
    this.dd = ('0' + dd).slice(-2);
    this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    this.updateDob.emit(this.updateDate);
  }
  /**
   * Handles year change and updates the date of birth.
   * @param yyyy Year value
   */
  changeYear(yyyy) {
    this.yyyy = yyyy;
    this.updateDate = this.mm + '-' + this.dd + '-' + this.yyyy;
    this.updateDob.emit(this.updateDate);
  }

  /**
   * Validates the date of birth and determines if it meets the minimum age requirement.
   * @param birthYear Year of birth
   * @param month Month of birth
   * @param day Day of birth
   */
  delegateBox(birthYear: any, month: any, day: any) {
    // Care Provider Information 18 < age check

    month.toString().length == 1 ? (month = '0' + month) : month;
    const birth = birthYear + '-' + month + '-' + day;
    const ageMS = Date.parse(Date()) - Date.parse(birth);
    const age = new Date();
    age.setTime(ageMS);
    const ageYear = age.getFullYear() - 1970;
    if (moment(birth).isValid() == false || ageYear < 0) {
      this.errMsg = true;
    } else {
      this.errMsg = false;
    }
    if (birth === '--') {
      this.finalError = false;
      this.updateDate = '';
      this.updateDob.emit(this.updateDate);
    } else {
      if (
        this.form.controls['dobDay'].hasError('required') ||
        this.form.controls['dobYear'].hasError('required') ||
        this.form.controls['dobMonth'].hasError('required')
      ) {
        this.finalError = true;
      } else {
        this.finalError =
          (this.form.controls['dobDay'].touched && this.form.controls['dobDay'].hasError('required')) ||
          (this.form.controls['dobMonth'].touched && this.form.controls['dobMonth'].hasError('required')) ||
          (this.form.controls['dobYear'].touched && this.form.controls['dobYear'].hasError('required')) ||
          this.form.controls['dobYear'].hasError('pattern') ||
          this.errMsg;
      }
    }
    this.errorCode.emit(this.finalError);
  }
}
