<div class="account-pages">
  <div class="container">
    <div class="text-center">
      <a href="/" class="auth-logo">
        <img src="assets/images/logo/medocity_logo.png" alt="medocity" />
      </a>
    </div>
    <div class="custom-wrapper">
      <div class="card overflow-hidden">
        <div class="card-body text-center">
          <div><img src="assets/images/logo/maintenance_logo.gif" alt="medocity" /></div>

          <div class="mt-4">
            <h1 class="text-center">Maintenance</h1>
            <form>
              <div class="form-group text-center">
                <label>We sincerely apologize for the inconvenience.</label>
                <label>We are currently undergoing a scheduled update. Service will return shortly.</label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
