<footer class="footer">
  <div class="status">
    <span><a target="_blank" href="https://medocity.statuspage.io">Status</a></span
    ><span class="color-dot" [ngClass]="getStatusMaintenance()"></span>
  </div>
  <div class="copyright-text">
    <span class="footer-logo">
      <img class="footer-web-logo" src="assets/images/logo/footer-logo.svg" alt="Medocity" />
      <!-- <img class="footer-mobile-logo" src="assets/images/logo/small/medocity.svg" alt="Medocity"/> -->
    </span>
    <!-- | {{ (isDashboardRoute ? 'dashboardFooterText' : 'footerText') | translate }}  -->
    © {{ year }} All rights reserved <span class="copyright-border">|</span> V{{ appVersion }}
  </div>
</footer>
