import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * Component for displaying a confirmation dialog.
 *
 * This component is used to show a dialog asking the user for confirmation with customizable options.
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  /** Creates an instance of ConfirmationDialogComponent.
   * @param dialogRef The MatDialogRef to manage the dialog reference.
   * @param data The data object containing configuration for the dialog.
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
  ) {}
}

/**
 * Interface for the data passed to the component.
 */
interface ConfirmationDialogData {
  /**
   * The title to be displayed in the dialog header.
   */
  title: string;
  /**
   * The message to be displayed in the dialog body.
   */
  message: string;
  /**
   * The label for the "Yes" button.
   */
  yesButtonLabel: string;
  /**
   * The label for the "No" button. Optional.
   */
  noButtonLabel?: string;
  /**
   * Flag indicating whether to show the cross icon in the dialog.
   */
  showCrossIcon: boolean;
  /**
   * Optional value emitted when the cross button is clicked.
   */
  crossButtonEmittedValue?: string;
}
