import { AfterContentChecked, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthenticationService } from './modules/authentication/authentication.service';
import { ClientsService } from './modules/clients/clients.service';
import { SharedService } from './shared/services/shared.service';
import { ToastrService } from 'ngx-toastr';

/**
 * Root component of the application.
 * Handles global application features such as spinner display,
 * dialog management, and client label settings.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentChecked {
  /**
   * The title of the application.
   */
  title = 'Provisioning V3';

  /**
   * Flag indicating whether the spinner is displayed.
   */
  spinner = false;

  /**
   * Constructor for root component of the application.
   * @param {SharedService} sharedService - Service for managing shared application state.
   * @param {AuthenticationService} authService - Service for handling authentication and token management.
   * @param {ClientsService} clientsService - Service for managing client-related operations.
   * @param {ChangeDetectorRef} cdRef - Service for manually triggering change detection.
   * @param {Router} router - Service for navigating between routes.
   * @param {MatDialog} dialog - Service for managing Angular Material dialogs.
   */
  constructor(
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private clientsService: ClientsService,
    private toastrService: ToastrService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.sharedService.spinner.subscribe((response) => {
      this.spinner = response;
    });
    this.closeAllDialog();
    this.startRefreshTokenTimer();
  }
  /**
   * Sets the client label based on the client service.
   * If no label is set, uses default values.
   * @returns {void}
   */
  setClientLabel(): void {
    if (this.clientsService.getClientLabel()) {
      this.clientsService.setClientLabel(
        this.clientsService.getClientLabel() || {
          managerUser: 'HCP',
          managedUser: 'Patient',
        },
      );
    }
  }

  /**
   * Closes all open dialogs when the route changes.
   * Also sets the client label when a route change is detected.
   * @returns {void}
   */
  closeAllDialog(): void {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe({
      next: (response) => {
        if (response) {
          this.setClientLabel();
          this.dialog.closeAll();
        }
      },
      error: (error) => {
        if (error) {
          //  console.log(error);
        }
      },
    });
  }

  /**
   * Starts the refresh token timer for managing authentication tokens.
   * @returns {void}
   */
  startRefreshTokenTimer() {
    this.authService.startRefreshTokenTimer();
  }

  /**
   * Manually triggers change detection to update the view.
   * This is called after the content has been checked.
   * @returns {void}
   */
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }
  /**
   * Listener for changes in web storage values (localStorage/sessionStorage).
   * Logs out user if 'role' key is modified.
   * @param event StorageEvent
   */
  @HostListener('window:storage', ['$event'])
  public webStorageValueChanged(event: StorageEvent): void {
    if (event && event.oldValue) {
      if (event.key === 'userDetails' || event.key === 'accessAfterLoggedIn') {
        this.authService.signOut();
        this.toastrService.error(
          `Your session has been terminated due to suspicious activity on the website's storage. For security purposes, you have been logged out.`,
        );
      }
    }
  }
}
