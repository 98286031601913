import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap, timeout } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
/**
 * Injection token for specifying the default timeout for HTTP requests.
 */
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
/**
 * HTTP interceptor for handling HTTP requests and responses.
 */
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  /** Creates an instance of the class.
   * @param defaultTimeout - The default timeout duration for HTTP requests.
   * @param authService - Service for managing authentication and authorization.
   * @param toastrService - Service for displaying toast notifications.
   * @param _sharedService - Service for shared functionalities across the app.
   */
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
    private _sharedService: SharedService,
  ) {}

  /**
   * Intercepts HTTP requests to add headers, manage request timeouts, and handle responses and errors.
   *
   * @param request - The outgoing HTTP request.
   * @param next - The HTTP handler to pass the request to the next interceptor or backend.
   *
   * @returns Observable of HTTP events.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has('loader')) {
      this._sharedService.spinner.next(false);
    } else {
      this._sharedService.spinner.next(true);
    }
    if (!request.headers.has('Content-Type')) {
      if (!(request.body instanceof FormData && (request.body.get('file') || request.body.get('attachment')))) {
        request = request.clone({
          setHeaders: { 'Content-Type': 'application/json' },
        });
      }
    }
    if (this.authService.isAuthorized()) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${this.authService.geToken()}` },
      });
    }
    request = request.clone({
      setHeaders: { app_name: environment.appName },
    });
    return next.handle(request).pipe(
      timeout(this.defaultTimeout),
      tap((event) => {
        if (event instanceof HttpResponse) {
          this._sharedService.spinner.next(false);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this._sharedService.spinner.next(false);
        this.showErrors(error, request);
        return throwError(error);
      }),
    );
  }
  /**
   * Displays error messages based on the HTTP error response.
   * Handles specific HTTP status codes and displays appropriate toast notifications.
   *
   * @param error - The HTTP error response.
   * @param request - The original HTTP request that caused the error.
   */
  showErrors(error: HttpErrorResponse, request: HttpRequest<unknown>) {
    let message = '';
    if (error) {
      switch (error.error.statusCode) {
        case 401:
          if (!request.url.includes('platform-login')) {
            //this.refreshToken(request, next);
            message = error.error.message;
            this.authService.signOut();
          } else {
            message = error.error.message;
          }
          break;
        case 500:
          message = 'Internal Server Error. The request could not be completed.';
          break;
        // case 400:
        //   //message = "Bad request";
        //   message = error.error.message;
        //   break;
        case 403:
          message = "Forbidden Request, You don't have permission to access this";
          break;
        case 404:
          message = 'Service not found';
          break;
        // case 405:
        //   message = error.error.message;
        //   break;
        // case 503:

        //   message = error.error.message;
        //   break;
        // case 406:
        //   message = error.error.message;
        //   break;
        // comment by anjum  two toster message coming if duplicate entry
        default:
          message = error.error.message;
          break;
      }
      if (message) {
        this.toastrService.error(message, error.error.error);
      }
    }
  }
  /**
   * Refreshes the authentication token when it is expired and retries the failed request.
   *
   * @param request - The original HTTP request that failed.
   * @param next - The HTTP handler to pass the request to the next interceptor or backend.
   */
  refreshToken(request: HttpRequest<unknown>, next: HttpHandler) {
    const body = new URLSearchParams();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refresh_token = localStorage.getItem('refresh_token') || '';
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', refresh_token);
    if (refresh_token) {
      this.authService
        .refreshToken(body, { headers: headers })
        .pipe(
          switchMap((response) => {
            this.authService.setTokens(response);
            return next.handle(request);
          }),
        )
        .subscribe({
          next: (response) => response,
          error: (error) => {
            if (error) {
              this.toastrService.error(error.error.message, error.error.error);
              this.authService.signOut();
            }
          },
        });
    }
  }
}
