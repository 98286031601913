import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
/**
 * Directive that conditionally renders a template based on the user's authentication status.
 *
 * This directive is used to control the visibility of elements based on whether the user is signed in.
 * It will only display the element if the user is authorized, otherwise, it will hide it.
 */
@Directive({
  selector: '[appLoadedIfSignedIn]',
})
export class LoadedIfSignedInDirective {
  /**
   * Creates an instance of the LoadedIfSignedInDirective.
   *
   * @param _authService The service responsible for handling authentication and authorization.
   * @param viewContainerRef Provides access to the view container to insert or clear views.
   * @param template The template reference that will be conditionally rendered.
   */
  constructor(
    private _authService: AuthenticationService,
    private viewContainerRef: ViewContainerRef,
    private template: TemplateRef<any>,
  ) {}

  /**
   * Initializes the directive by checking the user's authentication status and
   * conditionally rendering the template.
   */
  ngOnInit(): void {
    if (this._authService.isAuthorized()) {
      this.viewContainerRef.createEmbeddedView(this.template);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
