import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for sorting an array of objects based on a specified property and order.
 *
 * the provided property (key) and sort order. It supports both ascending and descending
 * order sorting.
 */
@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  /**
   * Transforms and sorts an array based on the provided property and order.
   *
   * @param array The array of objects to be sorted.
   * @param sortBy The property name to sort the array by.
   * @param order The order in which to sort the array. Accepts 'asc' for ascending
   *              or 'desc' for descending. Defaults to 'asc' if not provided.
   *
   * @returns The sorted array of objects based on the specified property and order.
   */
  transform(array, sortBy: string, order?: string) {
    const sortOrder = order ? order : 'asc'; // setting default ascending order
    return this.orderBy(array, sortBy, sortOrder);
  }
  /**
   * Transforms and sorts an array based on the provided property and order.
   *
   * @param array The array of objects to be sorted.
   * @param sortByKey The property name to sort the array by.
   * @param order The order in which to sort the array. Accepts 'asc' for ascending
   *              or 'desc' for descending. Defaults to 'asc' if not provided.
   *
   * @returns The sorted array of objects based on the specified property and order.
   */
  orderBy(array, sortByKey: string, order = 'asc') {
    return array.sort((a, b) => {
      if (a[sortByKey] < b[sortByKey]) return order === 'asc' ? -1 : 1;
      if (a[sortByKey] > b[sortByKey]) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }
}
