import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform user group types into a user-friendly format.
 * {{ 'Member' | userGrpType }}  // Outputs: 'Member'
 */
@Pipe({
  name: 'userGrpType',
  standalone: true,
})
export class UserGrpTypePipe implements PipeTransform {
  /**
   * Array of user group types with their corresponding view values.
   */
  // // Member', 'Observer', 'CoOwner
  userGroupTypes: Array<{ value: string; viewValue: string }> = [
    { value: 'Member', viewValue: 'Member' },
    { value: 'CoOwner', viewValue: 'Co-owner' },
    { value: 'Observer', viewValue: 'Observer' },
    { value: 'owner', viewValue: 'Owner' },
  ];

  /**
   * Transforms a user group type value to its corresponding view value.
   * @param value - The user group type value to be transformed.
   * @param args - Additional arguments (not used in this pipe).
   * @returns The transformed user group type view value or undefined if not found.
   */
  transform(value: unknown, ...args: unknown[]): unknown {
    const index = this.userGroupTypes.findIndex((ele) => ele.value === value);

    return this.userGroupTypes[index].viewValue;
  }
}
