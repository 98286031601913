import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

/**
 * Component for searching and managing communication-related items.
 */
@Component({
  selector: 'app-communication-search',
  templateUrl: './communication-search.component.html',
  styleUrls: ['./communication-search.component.scss'],
})
export class CommunicationSearchComponent implements OnInit {
  /** Form group for managing add/edit operations. */
  addEditMedication!: FormGroup;

  /**
   * Constructor for the CommunicationSearchComponent.
   * @param dialog - Angular Material dialog service for opening dialogs.
   */
  constructor(public dialog: MatDialog) {}

  /**
   * Angular lifecycle hook that initializes the component.
   */
  ngOnInit(): void {}
}
