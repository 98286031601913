<div class="confirmation-dialog">
  <div class="flex-between dialog-header">
    <h2>{{ data.title }}</h2>
    <div class="dialog-header-icons">
      <!-- <i-feather name="check" [mat-dialog-close]="true"></i-feather> -->
      @if (data && data.showCrossIcon) {
        <i-feather name="x" class="pointer" [mat-dialog-close]="data.crossButtonEmittedValue || true"></i-feather>
      }
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content mb-2">
    <p class="text-center mb-0">{{ data.message }}</p>
  </mat-dialog-content>
  <div>
    <div class="btn-group btn-center" *ngIf="data?.yesButtonLabel || data?.noButtonLabel">
      <button class="btn btn-secondary" type="button" *ngIf="data?.noButtonLabel" mat-dialog-close>
        {{ data.noButtonLabel }}
      </button>
      <button class="btn btn-primary" type="button" *ngIf="data?.yesButtonLabel" [mat-dialog-close]="true">
        {{ data.yesButtonLabel }}
      </button>
    </div>
  </div>
</div>
