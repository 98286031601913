import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

/**
 * A custom Angular pipe used to sanitize input values based on the specified type.
 * Utilizes Angular's `DomSanitizer` to ensure the safe usage of HTML, style, script, URL, and resource URL.
 */
@Pipe({
  name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
  /**
   * @param sanitizer - The `DomSanitizer` service used for sanitizing the input values.
   */
  constructor(protected sanitizer: DomSanitizer) {}

  /**
   * Transforms the input value by sanitizing it according to the specified type.
   *
   * @param value - The value to be sanitized.
   * @param type - The type of the value to be sanitized. Can be one of 'html', 'style', 'script', 'url', or 'resourceUrl'.
   * @returns A sanitized version of the input value. Returns a `SafeHtml`, `SafeStyle`, `SafeScript`, `SafeUrl`, or `SafeResourceUrl`
   * depending on the specified type. If the type is not recognized, returns the original value.
   */
  public transform(value, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return value;
    }
  }
}
