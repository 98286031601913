import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
/**
 * A component that provides pagination controls for navigating through paginated data. It uses Angular Material's `MatPaginator` for pagination functionality.
 */
@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [MatPaginator],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  /**
   * The options for pagination including the current page and page size. This input is used to configure the pagination controls.
   */
  @Input() paginationOptions;
  /**
   * An event emitter that emits the updated pagination options whenever the page is changed.
   */
  @Output() pagination: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  /**
   * A reference to the Angular Material paginator component. This is used to interact with the paginator's API.
   */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  /**
   * The current page index for pagination, zero-based.
   */
  pageIndex = 0;
  /**
   * An array of page size options available for selection in the paginator.
   */
  pageSizeOptions: number[] = [5, 10, 15, 20];
  /**
   * Holds the page event details for pagination changes.
   */
  pageEvent!: PageEvent;
  /**
   *  Updates the current page index based on pagination options.
   */
  ngOnChanges(): void {
    this.pageIndex = this.paginationOptions?.currentPage - 1 || 0;
  }
  /**
   * Handles page changes from the paginator and emits updated pagination options.
   * @param e - The `PageEvent` object containing details about the page change event.
   */
  handlePage(e: PageEvent): void {
    if (e.previousPageIndex && e.previousPageIndex > e.pageIndex) {
      this.paginationOptions.currentPage = e.pageIndex <= 0 ? 1 : e.pageIndex + 1;
    } else {
      this.paginationOptions.currentPage = e.pageIndex + 1;
    }
    this.pageIndex = this.paginationOptions.currentPage;
    this.paginationOptions.limit = e.pageSize;
    this.pagination.emit(this.paginationOptions);
  }
}
