import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { FileUploadComponent } from 'src/app/shared/modules/file-upload/components/file-upload/file-upload.component';
import { PipesModule } from '../pipes/pipes.module';
import { FileUploadRoutingModule } from './file-upload-routing.module';
/**
 * Array containing the FeatherModule configuration with all icons picked.
 */
const featherIconsModule = [FeatherModule.pick(allIcons)];

@NgModule({
  declarations: [FileUploadComponent],
  imports: [CommonModule, featherIconsModule, PipesModule, FileUploadRoutingModule],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
