<!-- header start -->
<header class="header-wrapper">
  <div class="header-left">
    @if (showMenuIcon()) {
      <div class="icon-buttons">
        <button
          class="btn btn-trigger"
          type="button"
          (click)="openNavMenuDroDownDialog()"
          [class]="{ 'close-menu': menu }"
        >
          <i-feather name="menu" class="feather-20"></i-feather>
          <i-feather name="x" class="feather-20"></i-feather>
        </button>
      </div>
    }
    <div class="header-logo">
      <img src="assets/images/logo/medocity_logo_white.svg" alt="Medocity" />
    </div>
  </div>
  <div class="header-right">
    <div class="hr-col active-user">
      <div class="active-user-toggle" id="activeUserDropdown" [matMenuTriggerFor]="profileMenu">
        <div class="active-user-icon">
          <img
            class="user-image rounded-circle profile-image"
            alt="Provisioning super user"
            [src]="userDetails?.profilePicture ? userDetails.profilePicture : ''"
            alt="Medocity"
            onErrorImg
          />
        </div>
        <div class="dropbtn">
          @if (userDetails?.name) {
            <span class="user-name">{{ userDetails?.name }}</span>
          }
          <i-feather name="chevron-down" class="feather-20"></i-feather>
        </div>
      </div>
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item class="active-user-logout" (click)="signOut()">
          <i-feather name="log-out" class="feather-20 text-danger"></i-feather>
          <span class="text-danger">Sign Out</span>
        </button>
      </mat-menu>
    </div>
  </div>
</header>
<!-- header end -->
