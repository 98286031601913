<div class="dialog-xs">
  <div class="dialog-header">
    <div class="dialog-header-icons justify-content-end">
      <i-feather name="x" class="pointer" mat-dialog-close></i-feather>
    </div>
  </div>
  <mat-dialog-content class="mat-dialog-content">
    <div class="center">
      <div>
        <div class="check-icon"><i-feather name="check"></i-feather></div>
        <h3 class="thank-you-message">{{ 'thankYouSubmission' | translate }}</h3>
      </div>
    </div>
  </mat-dialog-content>
  <div class="btn-group btn-center">
    <button mat-dialog-close aria-label="Close" class="btn btn-primary">
      {{ 'close' | translate }}
    </button>
  </div>
</div>
