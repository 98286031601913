import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { NavMenuDropDownComponent } from '../nav-menu-drop-down/nav-menu-drop-down.component';
import { FeatherModule } from 'angular-feather';
import { SharedService } from '../../services/shared.service';
import { MatMenuModule } from '@angular/material/menu';
import { OnErrorImgDirective } from '../../directives/on-error-img.directive';

/**
 * Component to render the header of the application.
 */
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [FeatherModule, MatMenuModule, OnErrorImgDirective],
  providers: [OnErrorImgDirective],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  /** Flag to indicate if the navigation menu is open */
  menu = false;
  /**
   * Dialog service for opening and managing dialogs in the dashboard.
   * The `MatDialog` service is used to open various dialogs in the application.
   */
  #dialog = inject(MatDialog);
  /** Injected shared service instance */
  #sharedService = inject(SharedService);
  /** Injected AuthenticationService for handling authentication requests and tokens */
  #authService = inject(AuthenticationService);

  /**
   * Getter to retrieve user details from the authentication service
   */
  get userDetails() {
    return this.#authService.getUserDetails();
  }

  /**
   * Signs out the user by calling the authentication service
   */
  signOut() {
    this.#authService.signOut();
  }

  /**
   * Opens the navigation menu drop-down dialog
   */
  openNavMenuDroDownDialog() {
    this.menu = true;
    const dialogRef = this.#dialog.open(NavMenuDropDownComponent, {
      panelClass: 'main-navigation-dialog',
      position: { top: '60px', left: '24px' },
      backdropClass: 'header-menu-backdrop',
    });
    dialogRef.afterClosed().subscribe(() => (this.menu = false));
  }
  /**
   * Checks if the user is authorized by verifying the presence of an access token.
   *
   * @returns True if authorized, otherwise false.
   */
  superAdmin() {
    return this.#authService.superAdmin();
  }
  /**
   * Determines whether the menu icon should be shown based on the presence of a client ID or super admin status.
   *
   * This method will return `false` if both the client ID is not present and the user is not a super admin.
   * Otherwise, it will return `true`.
   *
   */
  showMenuIcon() {
    if (!this.#sharedService.getClientId() && !this.superAdmin()) {
      return false;
    }
    return true;
  }
}
