<label for="" class="form-label">Date of birth</label>
<form [formGroup]="form">
  <div class="date-form-group">
    <div class="form-group">
      <label for="" class="form-label">Month </label>
      <input
        placeholder="MM"
        formControlName="dobMonth"
        type="text"
        digitOnly
        class="form-control"
        (change)="changeMonth(form.controls['dobMonth'].value)"
        (blur)="
          delegateBox(form.controls['dobYear'].value, form.controls['dobMonth'].value, form.controls['dobDay'].value)
        "
        onKeyPress="if(this.value.length==2) return false;"
      />
    </div>
    <div class="form-group">
      <label for="" class="form-label">Day</label>
      <input
        placeholder="DD"
        formControlName="dobDay"
        class="form-control"
        type="text"
        digitOnly
        (change)="changeDate(form.controls['dobDay'].value)"
        (blur)="
          delegateBox(form.controls['dobYear'].value, form.controls['dobMonth'].value, form.controls['dobDay'].value)
        "
        onKeyPress="if(this.value.length==2) return false;"
      />
    </div>
    <div class="form-group">
      <label for="" class="form-label">Year</label>
      <input
        placeholder="YYYY"
        formControlName="dobYear"
        class="form-control yy"
        type="text"
        maxlength="4"
        digitOnly
        (change)="changeYear(form.controls['dobYear'].value)"
        (blur)="
          delegateBox(form.controls['dobYear'].value, form.controls['dobMonth'].value, form.controls['dobDay'].value)
        "
      />
    </div>
  </div>

  <div class="danger" *ngIf="finalError">Valid Date Of Birth is required.</div>
</form>
