<div class="auth-wrapper p-3">
  <div class="logo text-center">
    <img src="assets/images/logo/medocity_logo.png" alt="Medocity logo" />
  </div>
  <div class="auth-container">
    <h1>{{ 'resetPassword' | translate }}</h1>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()">
      <div class="mb-3">
        <label for="password" class="form-label">{{ 'password' | translate }}</label>
        <input
          type="password"
          id="password"
          class="form-control"
          formControlName="password"
          [class]="{
            'is-invalid':
              resetPasswordFormControls.password.invalid && (submitted || resetPasswordFormControls.password.touched)
          }"
        />
        @if (resetPasswordFormControls.password.invalid && (submitted || resetPasswordFormControls.password.touched)) {
          <div class="invalid-feedback">
            @if (resetPasswordFormControls.password.errors?.['required']) {
              <div>
                {{ 'passwordIsRequired' | translate }}
              </div>
            }
            @if (
              resetPasswordFormControls.password.errors?.['minlength'] ||
              resetPasswordFormControls.password.errors?.['pattern']
            ) {
              <div>
                {{ 'passwordStrengthValidation' | translate }}
              </div>
            }
          </div>
        }
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">{{ 'confirmPassword' | translate }}</label>
        <input
          type="password"
          id="confirmPassword"
          class="form-control"
          formControlName="confirmPassword"
          [class]="{
            'is-invalid':
              resetPasswordFormControls.confirmPassword.invalid &&
              (submitted || resetPasswordFormControls.confirmPassword.touched)
          }"
        />
        @if (
          resetPasswordFormControls.confirmPassword.invalid &&
          (submitted || resetPasswordFormControls.confirmPassword.touched)
        ) {
          <div class="invalid-feedback">
            @if (resetPasswordFormControls.confirmPassword.errors?.['required']) {
              <div>
                {{ 'confirmPasswordIsRequired' | translate }}
              </div>
            }
            @if (resetPasswordFormControls.confirmPassword.errors?.['mustMatch']) {
              <div>
                {{ 'passwordAndConfirmPasswordMustBeMatched' | translate }}
              </div>
            }
          </div>
        }
        <p class="info-small">
          {{
            'yourPasswordMustHave8OrMoreCharactersUpper&LowercaseLettersAtLeastOneNumberAndOneSpecialCharacter'
              | translate
          }}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary px-5">{{ 'submit' | translate }}</button>
      </div>
      <div class="text-center pt-3 mb-0">
        <a routerLink="/login">{{ 'backToSignIn' | translate }}</a>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>
