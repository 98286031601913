import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
/**
 * A route guard service that prevents access to routes for users who are already authenticated.
 * If a user is authenticated, they are redirected to the `/clients` route.
 */
@Injectable({
  providedIn: 'root',
})
export class NotSignedInGuard {
  /** Creates an instance of the class.
   * @param _authService - Service for handling authentication logic.
   * @param router - Angular router service used for navigation.
   * @param location - Angular location service for interacting with the browser's URL.
   */
  constructor(
    private _authService: AuthenticationService,
    private router: Router,
    private location: Location,
  ) {}
  /**
   * Checks if the user is authenticated. If the user is authenticated, redirects them to the `/clients` route.
   * If the user is not authenticated, access to the requested route is granted.
   *
   * @param route - The activated route snapshot.
   * @param state - The router state snapshot.
   *
   * @returns Observable, Promise, boolean, or UrlTree indicating whether the route can be activated.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.isAuthorized()) {
      this.router.navigate(['/clients']);
      // this.router.
      // console.log(window.location.pathname);
      return false;
    }
    return true;
  }
}
