import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Directive to restrict input to numeric values only.
 *
 * This directive ensures that the associated input field accepts only numeric values by filtering
 * out non-numeric characters. It implements the `ControlValueAccessor` interface to work with Angular
 * forms.
 *
 * The directive is applied to input elements with the `[appOnlyNumber]` attribute selector.
 */
@Directive({
  selector: '[appOnlyNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlyNumberDirective),
      multi: true,
    },
  ],
})
export class OnlyNumberDirective implements ControlValueAccessor {
  /**
   * Callback function to handle value changes.
   */
  private onChange!: (val: string) => void;
  /**
   * Callback function to mark the field as touched.
   */
  private onTouched!: () => void;
  /**
   * Stores the current value of the field.
   */
  private value!: string;

  /**
   * Creates an instance of the OnlyNumberDirective.
   *
   * @param elementRef Provides direct access to the DOM element.
   * @param renderer Allows manipulation of the DOM element.
   */
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  /**
   * Listens to the `input` event and filters non-numeric characters from the input value.
   *
   * Updates the input value and notifies the form control of the change if necessary.
   *
   * @param value The current value of the input element.
   */
  @HostListener('input', ['$event.target.value'])
  onInputChange(value: string) {
    const filteredValue: string = this.filterValue(value);
    this.updateTextInput(filteredValue, this.value !== filteredValue);
  }

  /**
   * Listens to the `blur` event and marks the control as touched.
   */
  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  /**
   * Registers a function to be called when the control's value changes.
   *
   * @param fn The function to be called on value change.
   */
  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  /**
   * Registers a function to be called when the control is touched.
   *
   * @param fn The function to be called on touch.
   */
  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of the input element.
   *
   * @param isDisabled Boolean indicating whether the input should be disabled.
   */
  public setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  /**
   * Writes a new value to the input element.
   *
   * @param value The value to write to the input element.
   */
  public writeValue(value): void {
    const valueChange = value ? String(value) : '';
    this.updateTextInput(valueChange, false);
  }

  /**
   * Filters out non-numeric characters from the input value.
   *
   * @param value The input value to be filtered.
   * @returns The filtered numeric value.
   */
  public filterValue(value) {
    return value.replace(/[-][^0-9]*/g, '');
  }

  /**
   * Updates the input element's value and notifies the form control of the change if needed.
   *
   * @param value The new value for the input element.
   * @param propagateChange Boolean indicating whether to notify the form control of the change.
   */
  private updateTextInput(value: string, propagateChange: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }
}
