import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for transforming units based on user preferences stored in local storage.
 *
 * This pipe converts units according to the user's unit preference settings stored in
 * local storage. It supports converting temperature and weight units, as well as blood
 * glucose units, based on the user's preference.
 */
@Pipe({
  name: 'unitPreference',
})
export class UnitPreferencePipe implements PipeTransform {
  /**
   * Transforms the unit based on the user's preference.
   *
   * @param value The unit to be converted.
   * @param args Optional additional arguments (not used in the current implementation).
   *
   * @returns The converted unit based on the user's preference settings.
   */
  transform(value: string, ...args: string[]) {
    const parseData = localStorage.getItem('unitPreference');
    let unit = '';
    if (parseData) {
      if (parseData === 'standard' && value === 'C') {
        unit = 'F';
      } else if (parseData === 'standard' && value === 'Kg') {
        unit = 'lbs';
      } else if (localStorage.getItem('bloodGlucoseUnit') == 'mmol/L' && value === 'mg/dl') {
        unit = 'mmol/L';
      } else {
        unit = value;
      }
    }
    return unit;
  }
}
